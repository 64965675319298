import React, { useEffect, useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  Outlet,
  useLocation,
} from "react-router-dom";
import ChooseYourSetting from "./component/ApplicationPreview/ChooseYourSetting";
import SelectYourDiamond from "./component/ApplicationPreview/SelectYourDiamond";
import CompleteYourRing from "./component/ApplicationPreview/CompleteYourRing";
import Layout from "./component/Layout";
import MinedDiamond from "./component/common/SelectYourDiamond/MinedDiamond";
import Request from "./component/common/SelectYourDiamond/Request";
import ChooseSettingItemDetails from "./component/ApplicationPreview/ChooseSettingItemDetails";
import ColorPanel from "./component/ColorPanel";
import { useSelector } from "react-redux";
import i18n from "./i18n";
import { useCookies } from "react-cookie";
import SelectYourDiamoiondServices from "./services/select-your-diamond.service";

const PrivateRoute = () => {
  return (
    <Layout>
      <Outlet />
    </Layout>
  );
};
const App = () => {
  const search = useLocation().search;
  const [cookies] = useCookies(["lngCookie"]);
  const fontStyle = useSelector(
    (state) => state.previewColorReducer.fontFamily
  );
  const [language, setLanguage] = useState("English");
  const paramdealerId = new URLSearchParams(search).get("DealerLink");
  const pDealerId = new URLSearchParams(search).get("DealerID");

  useEffect(() => {
    if (language) {
      //// language check conditions
      let tempLanguage =
        language === "English" || language === "en"
          ? "en"
          : language === "Spanish" || language === "es"
          ? "es"
          : language === "Arabic" || language === "ar"
          ? "ar"
          : "en";
      i18n.changeLanguage(tempLanguage);
    }
  }, [language, i18n]);

  useEffect(() => {
    const root = document.documentElement;
    root.style.setProperty(
      "--dynamic-font-family",
      fontStyle || "LexendDeca-Regular"
    );
  }, [fontStyle]);

  useEffect(() => {
    const dealerID =
      paramdealerId !== undefined && paramdealerId !== null
        ? paramdealerId
        : pDealerId;
    handleLanguage(dealerID);
  }, [paramdealerId, pDealerId]);

  const languageCodes = {
    Spanish: "es",
    English: "en",
    Arabic: "ar",
  };
  const handleLanguage = (id) => {
    debugger;
    let inputData = {
      delarId: id,
    };
    SelectYourDiamoiondServices.GetLanguage(inputData)
      .then((response) => {
        var message = response.data.message;
        var responseData = response.data.responseData;
        if (message === "Success") {
          if (responseData) {
            setLanguage(languageCodes[responseData.language] || null);
          }
        }
      })
      .catch((error) => {
        console.log(error);
      });
  };
  return (
    <div style={{ fontFamily: fontStyle ? fontStyle : "LexendDeca-Regular" }}>
      <React.Fragment>
        <Routes>
          <Route path="/selectYourDiamond" element={<SelectYourDiamond />} />
          <Route exact path="/" element={<PrivateRoute />}>
            <Route path="/Mined" element={<MinedDiamond />} />
            <Route path="/Request" element={<Request />} />
            <Route path="/chooseYourSetting" element={<ChooseYourSetting />} />
            <Route path="/completeYourRing" element={<CompleteYourRing />} />
            <Route
              path="/viewDiamondDetails"
              element={<ChooseSettingItemDetails />}
            />
          </Route>
        </Routes>
        <ColorPanel></ColorPanel>
      </React.Fragment>
    </div>
  );
};

export default App;
